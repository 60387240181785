<template>
    <section id="ldap" class="fdb-block d-flex align-center align-items-center" style="background: #3983b8;">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-8 col-xs-8 col-lg-6 col-xl-4 text-center">
                    <div class="fdb-box">

                        <div class="row">
                            <div class="col" style="width:100px; max-height:100px;">
                                <img src="/images/siged2.png" style="max-width:100%; max-height:100%;" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col" style="text-align: initial;">
                                <h2 style="margin: 0;">Iniciar sesión</h2>
                                <div class="">Directorio activo</div>
                            </div>
                        </div>

                        <form v-on:submit.prevent="signin()">
                            <div class="row">
                                <div class="col">
                                    <input type="text" v-model="form.username" class="form-control"
                                        placeholder="Usuario o correo electrónico" required autofocus>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <input type="password" v-model="form.password" class="form-control mb-1"
                                        placeholder="Contraseña" required>

                                </div>
                            </div>
                            <div class="row d-flex" style="justify-content: center;">
                                <div class="col">
                                    <button class="btn btn-primary w-100"
                                        style="align-items: center; color: #fff !important;" type="submit">
                                        Iniciar sesión
                                    </button>
                                </div>
                            </div>
                        </form>

                        <div v-if="message" class="row">
                            <div class="col">
                                <div class="alert alert-danger" role="alert">
                                    {{ message }}
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col">
                                <p><a href="http://www.archivosysistemas.com" target="_blank">Archivos y Sistemas AYS</a> -
                                    Copyright {{
                                        new Date().getFullYear() }}
                                    - <router-link :to="{ name: 'tyc' }">Términos y condiciones</router-link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            form: {
                username: null,
                password: null
            },
            message: null
        };
    },
    methods: {
        async signin() {
            try {
                let url = "/passaport/ldap"

                const response = await this.axios.post(url, this.form)

                window.opener.postMessage(response.data, "*");

                window.close();
            } catch (error) {
                console.log(error);
                this.message = error.response.data.msg || "";
            }
        },
    },
}
</script>

<style>
html,
body,
#ldap {
    height: 100%;
}

body>div {
    height: 100%;
}
</style>